import { createContext, useState, useContext, useEffect } from 'react';

import { getLanguage } from '../utils/index.js'
import { localizeText } from '../lang/index.js';
import { fetchBbApi } from '../utils/bullbitcoin.js';

import { UserContext } from './user.js';

export const LanguageContext = createContext({
    lang: "",
    setLanguage: () => {},
});

export const LanguageProvider = ({ children }) => {
  const user = useContext(UserContext)
  const [language, setLanguage] = useState(getLanguage() || 'en');

  const setAndUpdateLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    updateLanguage(newLanguage);
  }

  const updateLanguage = (newLanguage) => {
    if(!user.data) {
      return
    }

    fetchBbApi({
      service: 'api-users',
      method: 'saveUserPreferences',
      params: {
        userPreferences: {
          "LANGUAGE": newLanguage.toUpperCase(),
        }
      }
    })
  }

  const languageContext = {
    ...localizeText(language),
    lang: language,
    setLanguage: setAndUpdateLanguage
  };

  useEffect(() => {
    const appLanguage = getLanguage()
    const userLanguage = user.data?.userPreferences?.LANGUAGE
    if(appLanguage && appLanguage !== userLanguage) {
      setAndUpdateLanguage(appLanguage)
    } else if (!appLanguage && userLanguage) {
      setLanguage(userLanguage.toLowerCase())
    } else if(!appLanguage && !userLanguage && navigator.language) {
      setAndUpdateLanguage(navigator.language.split('-')[0])
    }
  }, [user.data]);

  return (
    <LanguageContext.Provider value={languageContext}>
      {children}
    </LanguageContext.Provider>
  );
};